import React, { useCallback, useEffect, useState } from "react";

import "./App.css";
import { useNavigate } from "react-router-dom";
import images_nodata from "../src/images/new-menu-bg/images_nodata.png";
import { connect, useDispatch, useSelector } from "react-redux";
import ContentBlogLoader from "./Components/Loader/contentLoader";
import BodyBlogLoader from "./Components/Loader/bodyLoader";
import { fetchPoems } from "./Actions/poems";
import Note from "./utils/Note";

function DemoThree({parasize, menuvalue, font_type, bgValue, bgparaValue,colorFont,poems, lineHeight}) {
  const [success,setsuccess] = useState([])
  const [getdatas,setgetdatas] = useState([])
  const currentYear = new Date().getFullYear(); //
  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(fetchPoems(setsuccess))
},[])

useEffect(()=>{
  if(poems?.length !== 0){
    setgetdatas(poems)
    setsuccess(true)
  }
},[poems])

  if(success == false){
    return(
      <React.Fragment>
      <div className="contentLoader">
      <ContentBlogLoader />
      </div>
      <div className="skeletonLoader">
      <BodyBlogLoader />
      </div>
      <div className="skeletonLoader">
      <BodyBlogLoader />
      </div>
      <div className="skeletonLoader">
      <BodyBlogLoader />
      </div>
    
    </React.Fragment>
    )
  }
  return (

    <div className="App">
      
      {success == true ? getdatas.map((item)=>{
        {}
        return (
          <React.Fragment>
          <div className="container-fluid post-container">
        <div className="row">
          <div className={`site-content ${font_type}`}>

          <div class={`post-sec frame1 ${bgValue}`}>
          {item?.pageName === "poems" && item.note !== null? (
            <div
            className="note-container"
            style={{
              paddingTop: 0,
              display: "flex",
              flexDirection: "column",
            }}
            >
             
              {item.title !== null ? (
                <div className="title-div-poem-note">
                 <h1
                   class={`entry-title`}
                  dangerouslySetInnerHTML={{
                    __html: item.title,
                  }}
                ></h1>
                </div>
              ) : null}
              { item?.pageName === "poems" && item.note !== null ? (
                <React.Fragment>
                  {item?.pageName === "poems"  ? (
                    <div className="sticky-box">
                    <Note note={item.note} bg_color={item.background_colour}/>
                  </div>
                  ) : null}
                </React.Fragment>
              ) : null}
            </div>
          ) : (
            <div className="title-div-poem">
            {item.title.length !== 11 ? (
              <div>
              <h1
                class={`entry-title`}
                dangerouslySetInnerHTML={{
                  __html: item.title,
                }}
              >
              
              </h1></div>
            ) : null}
            </div>
          )}
                          {item.imageUrl ? (
                            <div style={{
                              width: '100px',  // Set a smaller width
                              height: '100px', // Set a smaller height
                              overflow: 'hidden',  // Ensures the image fits within the container
                              borderRadius: '8px', // Adds slight rounding to the corners
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                          }}>
                              <img 
                                   src={item.imageUrl} 
                                   alt="image" 
                                   style={{
                                      width: '100%',  // Makes the image take the full width of the div
                                      height: 'auto', // Maintains the image aspect ratio
                                      objectFit: 'cover'  // Ensures the image is cropped to fit
                                   }} />
                          </div>
                          
                          ) : (
                            ""
                          )}
                          <div
                            className="desc_wrapper-poems"
                          >
                            {/* {item?.pageName === "poems" && item.note !== null? <div className="poem-style">
              <div style={{ fontSize: `${parasize}px`,color:`#000080`,width:'100%', display: 'inline-block', wordWrap: 'break-word',alignSelf:'center'}} class={`entry-content ${bgparaValue}`} dangerouslySetInnerHTML={{ __html: item.description }}>
             
              </div>
              {item?.pageName === "poems" ?
                <div><Note note={item.note}/></div>:null
              }
              </div>:
              <> */}
              <div
              style={{
                fontSize: `${parasize}px`,
                color: `${item.fairytale_content_colour? item.fairytale_content_colour:'#0F307A'}`,
                width: "100%",
                display: "inline-block",
                whiteSpace: "pre-wrap",
                alignSelf: "center",
              }}
              className={`entry-content ${bgparaValue}`}
              dangerouslySetInnerHTML={{
                __html: item.description.replace(/&nbsp;/g, " "),
              }}
            ></div>
                            {/* </>} */}
                          </div>
                          
                        </div>
                        {item?.pageName === "poems"? (
                            <div
                              className={`poem-reflection-footer`}
                              style={{
                               
                                fontSize:12,
                                fontFamily:`${bgparaValue}`,

                              }}
                            >
                              <h>
                              © {currentYear} Ritu Dhillon All Rights Reserved.{"     "}
                              </h>
                              <h>
                                {""}
                                {item.place ? `${item.place},` : ""}{" "}
                                {item.year ? item.year : ""}
                              </h>
                            </div>
                          ) : null}
          </div>
        </div>
      </div>
      <div class="separator"></div>
      </React.Fragment>
      )
      })  : ''}
      {getdatas.length === 0? 
      <div className="" style={{alignSelf:'center',marginTop:100,marginLeft:'auto',marginRight:'auto',display:'flex',flexDirection:'column'}}>

        <img src={images_nodata} alt="" width={100} height={100} style={{margin:'auto'}}/>
        <label style={{alignSelf:'center',marginTop:30,marginLeft:'auto',marginRight:'auto',display:'flex',flexDirection:'column'}}>No Poems found!</label>
      </div> 
    :""
     }       
    </div>
  );
}

const mapStateToProps = state => ({
  poems: state.enroll.poems,
});

export default connect(mapStateToProps)(DemoThree);
